import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import SignUp from "./SignUp"
import adelphiLogo from "images/adelphi-logo.svg"
import Social from 'components/shared/Social'
import Region from "utils/Region"


const BlackBg = styled.div`
    background-color: black;
    color: white;
    font-size: ${props => props.theme.font.size.sm};
    padding: 1rem;
    
    
    .narrow {
        margin: 0 auto;
        max-width: 700px;
    }

    a {
        color: white;
        text-decoration: none;
        font-family: ${props => props.theme.font.family.base} !important;

        &:hover {
            color: ${props => props.theme.colors.red};
        }
    }
`

const Footer = (props) => {
    return(
        <footer>
            <BlackBg>
                {props.title === "Home" &&
                <p className="mt-2 font-weight-bold text-white text-center narrow"><span className="bold">Back To The Future: The Musical</span> thank the Government’s Culture Recovery Fund so that we can continue to be #HereForCulture</p>
                }
                {!props.signUpHidden &&
                <SignUp />
                }
                <Social footer />
                <Container className="text-center">
                    
                        
                    <Row className="justify-content-center pt-3">
                        <Col xs="auto">
                            <a href="https://lwtheatres.co.uk/theatres/adelphi/" target="_blank" rel=" noopener"><img src={adelphiLogo} alt="Adelphi Theatre" width="150" /></a>
                        </Col>
                    </Row>
                    <Row className="justify-content-center pt-2 pb-3">
                        <Col xs="auto">
                            <p className="font-weight-bold">Adelphi Theatre, Strand, London, WC2R 0NS, UK</p>
                        </Col>
                    </Row>
                    <Region footer region="london" />
                    <Row className="justify-content-center py-3">
                        <Col xs="auto">
                            &copy; Colin Ingram Ltd. <br className="d-md-none" /> Website by <a href="https://dewynters.com" target="_blank" rel="noopener ">Dewynters</a> <br className="d-md-none" /> <span className="d-none d-md-inline">|</span>   <Link to="/london/terms-conditions/">Terms and Conditions</Link>  <br className="d-md-none" /><span className="d-none d-md-inline">|</span>  <Link to="/london/privacy-policy/">Privacy Policy</Link>  |  <Link to="/london/cookies-policy/">Cookies Policy</Link>
                        </Col>
                    </Row>
                </Container>
            </BlackBg>
        </footer>
    )
}

export default Footer