import React from "react"
import Layout from "../../components/Layout/LayoutUSTour"
import PageHeader from "../../components/shared/PageHeader"
import NewYorkTextBlock from "../../components/Education/NewYorkTextBlock"


export default function Education() {
  return (
    <Layout 
    title="Education | North American Tour | Back to the Future the Musical"
    description="Cast & Creative | The official website for Back to the Future The Musical - North American Tour. Landing in a city near you from Summer 2024. "
    >
      <PageHeader city="new-york" title="Education" />
        <NewYorkTextBlock />
    </Layout>
  )
}