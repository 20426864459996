import React from 'react'
import StyledButton from 'components/shared/StyledButton'
import styled from 'styled-components'

const LegalBackToHomeStyles = styled.nav`
    width: 100%;
    section  {
        padding: .5rem 0;
        width: 90%;
        margin: 0 auto;
    }
    
`
const LegalBackToHome = () => {
  return (
    <LegalBackToHomeStyles>
        <section>
            <StyledButton to="/">
                HOME
            </StyledButton>
        </section>
    </LegalBackToHomeStyles>
  )
}

export default LegalBackToHome