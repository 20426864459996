import React from "react";
import PageHeader from "components/shared/PageHeader";
import styled, { ThemeProvider } from "styled-components";
import { media } from "utils/Media";
import { Script } from "gatsby"


import SEO from "utils/Seo";
import theme from "utils/Theme";
import GlobalStyles from "utils/GlobalStyles";
import "scss/main.scss";
import LegalBackToHome from "components/new-york/legalBackToHome";
import Footer from "../components/Footer/Footer";

import StyledButton from "../components/shared/StyledButton.js";
import ReactPlayer from "react-player";

const PageStyles = styled.section`
  width: 100%;
  background-color: black;
  .uppercase {
    text-transform: uppercase;
  }
  .cid_1 {
    display: none!important;
  }
  .block {
    display: block;
    margin-top: 1rem;
    margin: 0 auto;
    text-align: center;
  }
  .iframe {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
    iframe {
        width: 100%;
        text-align: center;
        margin: 0 auto;
    }
  }
  .text {
    width: 90%;
    margin: 2rem auto;
    max-width: 1440px;
    color: white;
    .video {
        width: 100%;
        height: auto;
        max-width: 800px;
        margin: 1rem auto 4rem auto;
    }
    .button-wrap {
      margin: 1rem auto;
      display: flex;
      flex-direction: column;
      max-width: 900px;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;
      align-items: center;
      @media ${media.md} {
        gap: 1rem;
        
    }
      div {
        text-align: center;
        width: 100%;
        margin: 0;
        @media ${media.md} {
            width: 100%;
            max-width: 400px;
        }
      }
      @media ${media.md} {
        flex-direction: row;
      }
    }

    .resources {
      margin-top: 2rem;
      text-transform: uppercase;
      font-size: 2rem;
      font-family: Proxima Nova W01 Black;
    }
    p {
      text-align: center;
      max-width: 950px;
      margin: 0 auto;
      padding-bottom: 1rem;
      @media ${media.md} {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
  .rules {
    width: 90%;
    margin: 2rem auto;
    max-width: 1440px;
    color: white;
    ol {
      max-width: 950px;
      margin: 0 auto;
      padding: 0 0 0 1rem;
      li {
        padding-bottom: 10px;
      }
    }
    p {
      text-align: left;
      max-width: 950px;
      margin: 0 auto;
      padding-bottom: 1rem;
      @media ${media.md} {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
`;

const StyledButtonCustom = styled(StyledButton)`
  /* max-width: 320px; */
  width: 100%;
  /* max-width: 300px; */
`;

const TermsPage = () => (
    <ThemeProvider theme={theme}>
        <GlobalStyles />
        <SEO title="Audition" description="Audition" />
        <LegalBackToHome />
        <PageHeader title="CALLING ALL DREAMERS!" city="us-tour" />
        <PageStyles>
            <div className="text">
            <p>Great Scott! To celebrate the 40th anniversary of&nbsp;<em>Back to the Future</em>, co-creator Bob Gale and the team have launched a global talent search to find a new Marty McFly.</p>
<p>Applications with or without formal training are welcome and the team are looking for potential Martys to play the role in any of the global productions of&nbsp;<em>Back to the Future The Musical</em>.</p>
<p>You gotta start somewhere, so if you&rsquo;re interested fill out the form below and upload a video of yourself singing Marty&rsquo;s iconic opening number&nbsp;<em>It&rsquo;s Only A Matter of Time</em>&nbsp;using the backing track and sheet music below.</p>
                <div className="video">
                    <ReactPlayer url="https://youtube.com/shorts/PLFj7LkV47Y?feature=share"      controls={true}
                                        width="100%"
                                        height="auto"
                                        className="w-full h-auto aspect-video" />
                </div>
                <div>
                    <p className="resources">
                        <strong>Download Resources</strong>
                    </p>
                    <div className="button-wrap">
                        <div>
                            <StyledButtonCustom
                                download
                                rel="noopener"
                                href="/audition/its-only-a-matter-of-time-marty-audition-cut-10-02-22.pdf"
                            >
                                SHEET MUSIC
                            </StyledButtonCustom>
                        </div>
                        <div>
                            <StyledButtonCustom
                                download
                                target="_blank"
                                rel="noopener"
                                href="/audition/only-a-matter-of-time-marty-audition-piano-track.mp3"
                            >
                                TRACK
                            </StyledButtonCustom>
                        </div>
                        <div>
                            <StyledButtonCustom
                                download
                                target="_blank"
                                rel="noopener"
                                href="/audition/marty-mcfly-character-breakdown.pdf"
                            >
                                Character Breakdown
                            </StyledButtonCustom>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iframe">
                <iframe
                    id="JotFormIFrame-250084338954058"
                    title="Audition Form"
                    onLoad={() => window.parent.scrollTo(0, 0)}
                    allowTransparency="true"
                    allow="geolocation; microphone; camera; fullscreen"
                    src="https://form.jotform.com/250084338954058"
                    frameBorder="0"
                    // style={{ minWidth: "100%", maxWidth: "100%", height: "539px", border: "none" }}
                    scrolling="no"
                ></iframe>
            </div>
            <div className="rules">
                <p>
                    <strong className="uppercase">Rules</strong>
                </p>
                <div>
                    <ol>
                        <li>
                            Your video submission must be an authentic recording of yourself,
                            with no audio-editing.
                        </li>
                        <li>
                            Video uploads / open call is open from 14th January 2025&nbsp;
                            <strong>until midnight</strong>&nbsp;on&nbsp;
                            <strong>28th January 2025</strong>. After the closing date, the
                            team will review all the videos and invite anyone they feel could
                            be right for the role to a formal audition which will take place
                            in London w/c 10 February 2025.&nbsp;
                        </li>
                        <li>Entrants must be 18+&nbsp;</li>
                    </ol>
                </div>
            </div>
            <div className="rules">
                <p>
                    <strong className="uppercase">TERMS & CONDITIONS </strong>
                </p>
                <div>
                    <ol>
                        <li>
                            Proof of age, identity, residency, and eligibility may be
                            requested. The Production&rsquo;s decision as to the eligibility
                            of individual entrants will be final and no correspondence will be
                            entered into.
                        </li>
                        <li>
                            The Production reserves the right to reshare any content submitted
                            to the cast search via the Back to the Future Social Channels.
                        </li>
                        <li>
                            The Production reserves the right to cancel or alter the scheme at
                            any stage. In this event, a notice will be posted on this website.
                        </li>
                        <li>
                            The Production reserves the right to amend these terms and
                            conditions at any stage, including changing or substituting the
                            selection process and the development opportunities. In this
                            event, a notice will be posted on this website.
                        </li>
                        <li>
                            The Production&rsquo;s decision is final, and no correspondence
                            will be entered into.
                        </li>
                    </ol>
                </div>
            </div>
        </PageStyles>
        {/* <Footer /> */}
    </ThemeProvider>
);

export default TermsPage;